exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-blog-listing-tsx": () => import("./../../../src/templates/blogListing.tsx" /* webpackChunkName: "component---src-templates-blog-listing-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-change-log-tsx": () => import("./../../../src/templates/changeLog.tsx" /* webpackChunkName: "component---src-templates-change-log-tsx" */),
  "component---src-templates-country-page-tsx": () => import("./../../../src/templates/countryPage.tsx" /* webpackChunkName: "component---src-templates-country-page-tsx" */),
  "component---src-templates-glossary-item-tsx": () => import("./../../../src/templates/glossaryItem.tsx" /* webpackChunkName: "component---src-templates-glossary-item-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../../../src/templates/legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-resource-listing-tsx": () => import("./../../../src/templates/resourceListing.tsx" /* webpackChunkName: "component---src-templates-resource-listing-tsx" */),
  "component---src-templates-resource-tsx": () => import("./../../../src/templates/resource.tsx" /* webpackChunkName: "component---src-templates-resource-tsx" */)
}

