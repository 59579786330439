import React from 'react';
import { IntercomProvider } from 'react-use-intercom';

import type { GatsbyBrowser } from 'gatsby';

const getAbsoluteOffsetTop = (element: HTMLElement) => {
  const pos = element.getBoundingClientRect();
  const currentY = window.scrollY;

  return currentY + pos.top - 100;
};

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - height of any persistent nav -> document.querySelector(`nav`)
 */
const scrollToAnchor = (location: Location, mainNavHeight = 0) => {
  // Check for location so build does not fail
  if (location && location.hash) {
    const hashEl = document.querySelector(`${location.hash}`);
    if (hashEl) {
      const item = getAbsoluteOffsetTop(hashEl as HTMLElement);

      window.scrollTo({
        top: item - mainNavHeight,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  return true;
};

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({ routerProps }) => {
  const { location } = routerProps;

  if (location.state) {
    const { disableScrollUpdate } = location.state;
    if (!location?.hash || location.hash === '') {
      window.scrollTo(0, 0);

      return !disableScrollUpdate;
    } else {
      scrollToAnchor(location, 0);

      return false;
    }
  }

  return false;
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => (
  <IntercomProvider appId={process.env.INTERCOM_ID || 'qfkktidc'} autoBoot>
    {element}
  </IntercomProvider>
);
